<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'questionnaires.categories' }"
          >Categories</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="form">
      <div class="display-1 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>

      <div class="d-flex my-6">
        <v-form ref="form" v-if="form" class="user-form">
          <v-divider></v-divider>

          <h3 class="py-5 primary--text">Category Details</h3>

          <label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.name"
            :error-messages="form.$getError('name')"
            :loading="loading"
          ></v-text-field>

          <div class="mt-2">
            <v-btn
              color="error"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              @click="deleteDialog = true"
              >Delete</v-btn
            >
          </div>
          <div class="mt-4">
            <v-btn
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              @click="updateCategoryDetails"
              :loading="form.$busy"
              >Update</v-btn
            >
          </div>
        </v-form>

        <v-dialog v-model="deleteDialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Delete ?</v-card-title>

            <v-card-text
              >Are you sure you want to delete this category ?</v-card-text
            >

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="deleteDialog = false">Cancel</v-btn>

              <v-btn
                color="red"
                text
                @click="deleteCategory"
                :loading="isLoadingDelete"
                >Yes, Delete please</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

import Form from '@/utils/form'

export default {
  name: 'CategoryDetails',

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline
      },
      loading: false,
      avatarLoading: false,
      deleteDialog: false,
      form: null,
      showCropperModal: false,
      avatarTimestamp: new Date().getTime(),
      tabItems: [{ tab: 'Info' }],
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      showModal: false,
      isLoadingDelete: false
    }
  },

  computed: {
    ...mapState({
      category: state => state.questionnaireCategory.categoryDetails,
      authUser: state => state.auth.user
    }),
    displayName() {
      return this.form.name || 'hey'
    }
  },

  methods: {
    ...mapActions({
      getCategoryDetails: 'questionnaireCategory/getCategoryDetails',
      updateCategory: 'questionnaireCategory/updateCategory'
    }),

    ...mapMutations({
      clearCategoryDetails: 'questionnaireCategory/clearCategoryDetails'
    }),

    async getCategory() {
      this.loading = true
      await this.getCategoryDetails(this.$route.params.id)
      this.form = new Form(this.category)
      this.loading = false
    },

    async updateCategoryDetails() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateCategory(this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Category successfully updated!', 'success')
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    deleteCategory() {
      this.isLoadingDelete = true
      this.category.delete().then(() => {
        this.isLoadingDelete = false
        this.deleteDialog = false
        this.showSnackbar('Category deleted successfully!')
        this.$router.replace('/questionnaires/categories')
      })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  created() {
    this.getCategory()
  },

  destroyed() {
    this.clearCategoryDetails()
  },

  watch: {
    $route() {
      this.getCategory()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  width: 400px;

  @media (max-width: 420px) {
    width: 100%;
  }
}
</style>
